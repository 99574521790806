<template>
    <div class="modular-space">
        <div class="modular-container">
            <div class="modular-kitchen">
                <div class="modular-image">
                    <template
                        v-if="
                            q === 'SD' &&
                                selectedQuote.is_gltf_available === true
                        "
                    >
                        <iframe
                            src="https://uat-uportal.siloho.com/"
                            id="low-render-frame"
                            width="100%"
                            height="100%"
                        />
                    </template>
                    <template v-else>
                        <img :src="selectedImg" class="image" />
                    </template>
                    <div
                        v-if="
                            selectedQuote.is_gltf_available === false &&
                                q === 'SD'
                        "
                        class="light-not-overlay"
                    >
                        Light version is not available for this scene
                    </div>
                </div>
                <div
                    v-if="showKitchenTypes"
                    class="kitchen-types"
                    tabindex="0"
                    @focus="showBrandOptionMenu(false)"
                    @blur="hideKitchenType()"
                >
                    <button
                        class="design-comparision-mobile-btn"
                        v-if="windowWidth < 1100 && showBrandOptions == false"
                    >
                        Design Comparison
                    </button>
                    <button
                        class="design-comparision-mobile-btn"
                        v-if="windowWidth < 1100 && showBrandOptions == true"
                    >
                        Brand Comparison
                    </button>
                    <div class="kitchens">
                        <div
                            v-for="(kitchen, index) in quoteList.data.quotes"
                            :key="index"
                            class="kitchen"
                            @click="showBrandOptionMenu(true, kitchen)"
                        >
                            <div>
                                <p class="type-name">
                                    {{ kitchen.quotes_type }}
                                </p>

                                <img
                                    :src="kitchen.quotes_type_image"
                                    class="type-image"
                                />
                            </div>
                            <div
                                class="kitchen-menu"
                                v-if="
                                    showBrandOptions == true &&
                                        kitchen.quotes_type ==
                                            selectedKitchenType &&
                                        windowWidth > 1100
                                "
                            >
                                <div class="menu-details">
                                    <p class="kitchen-menu-name">
                                        {{ kitchen.quotes_type }}
                                    </p>

                                    <img
                                        :src="
                                            kitchen.selected_quotes_type_image
                                        "
                                        class="kitchen-menu-image"
                                    />
                                </div>
                                <div
                                    v-for="(brand,
                                    index) in kitchen.vendor_quotes"
                                    :key="index"
                                    class="brands"
                                    :class="{
                                        'selected-brand':
                                            brand.vendor_id ===
                                                selectedQuote.vendor_id &&
                                            kitchen.quotes_type == kitchenType,
                                    }"
                                    @click="
                                        changeQuoteData(
                                            'brand',
                                            kitchen.quotes_type,
                                            brand.vendor_id,
                                        )
                                    "
                                >
                                    <img
                                        :src="
                                            brand.vendor_image
                                                ? brand.vendor_image
                                                : ''
                                        "
                                        class="brand-image"
                                    />
                                    <p
                                        class="brand-price"
                                        :class="
                                            requiredDomain == false
                                                ? 'blur'
                                                : ''
                                        "
                                    >
                                        ₹{{
                                            convertToIndianNumberSystem(
                                                brand.quotes_list[0].price,
                                            )
                                        }}
                                    </p>
                                </div>

                                <div class="comparision-button">
                                    See Brand Comparison
                                </div>
                            </div>
                        </div>
                        <template v-if="windowWidth < 1100">
                            <div
                                class="brands-mobile"
                                v-if="showBrandOptions == true"
                            >
                                <div class="menu-details">
                                    <img
                                        :src="
                                            kitchenInfo.selected_quotes_type_image
                                        "
                                        class="kitchen-menu-image"
                                    />

                                    <p class="kitchen-menu-name">
                                        {{ kitchenInfo.quotes_type }}
                                    </p>
                                </div>
                                <div
                                    v-for="(brand,
                                    index) in kitchenInfo.vendor_quotes"
                                    :key="index"
                                    class="brand"
                                    :class="{
                                        'selected-brand':
                                            brand.vendor_id ===
                                                selectedQuote.vendor_id &&
                                            kitchenInfo.quotes_type ==
                                                kitchenType,
                                    }"
                                    @click="
                                        changeQuoteData(
                                            'brand',
                                            kitchenInfo.quotes_type,
                                            brand.vendor_id,
                                        )
                                    "
                                >
                                    <img
                                        :src="
                                            brand.vendor_image
                                                ? brand.vendor_image
                                                : ''
                                        "
                                        class="brand-image"
                                    />
                                    <p
                                        class="brand-price"
                                        :class="
                                            requiredDomain == false
                                                ? 'blur'
                                                : ''
                                        "
                                    >
                                        ₹{{
                                            convertToIndianNumberSystem(
                                                brand.quotes_list[0].price,
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <button
                        class="design-comparision-btn"
                        v-if="windowWidth > 1100"
                    >
                        Design Comparison
                    </button>
                </div>
                <div
                    v-else
                    @click="showKitchenTypes = true"
                    class="kitchen-menu-btn"
                >
                    <div class="selected-type">
                        {{ kitchenType.toUpperCase() }}
                    </div>
                    <div class="selected-vendor-image">
                        <img :src="vendorImage" />
                    </div>
                </div>
                <!-- <div class="switch-btn">
                    <div
                        class="hd-btn"
                        :class="{
                            'q-active': q === 'HD',
                        }"
                        @click="switchRender('HD')"
                    >
                        HD
                    </div>
                    <div
                        class="sd-btn"
                        :class="{
                            'q-active': q === 'SD',
                        }"
                        @click="switchRender('SD')"
                    >
                        SD
                    </div>
                </div> -->
                <div class="camera-btn-location">
                    <button class="camera-btn" @click="switchcamera">
                        {{ btnvalue }}
                    </button>
                </div>
            </div>

            <div class="modular-embellishment" v-if="windowWidth > 1100">
                <Tabs :isselected="0" :modularDesign="true" :fullWidth="true">
                    <Tab :title="`Embellishment`">
                        <div
                            class="embell-options"
                            :class="{
                                'embells-disabled':
                                    selectedQuote.ivm_available == false,
                            }"
                        >
                            <!-- <div
                                v-for="(quote, index) in quoteList.payloads"
                                :key="index"
                            >
                                <template v-if="selectedQuote.id == quote.id"> -->
                            <div
                                v-for="(embellishments, indx) in quoteList.data
                                    .embellishments"
                                :key="indx"
                            >
                                <Embellishments
                                    :embellishment="embellishments"
                                    @onloadData="onloadData"
                                    @selectedEmbellishment="
                                        updateEmbellishments
                                    "
                                />
                            </div>
                            <!-- </template>
                            </div> -->
                            <div
                                v-if="selectedQuote.ivm_available === false"
                                class="embellishments-overlay"
                            >
                                <p>
                                    For selected vendor visualization not
                                    avaliable
                                </p>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
                <button class="modular-btn" @click="nextAction">Next</button>
            </div>
        </div>
        <div class="modular-options">
            <Tabs :isselected="0" :centerHeader="windowWidth > 1100">
                <Tab v-if="windowWidth < 1100" :title="`Embellishment`">
                    <div
                        class="embell-options"
                        :class="{
                            'embells-disabled':
                                selectedQuote.ivm_available == false,
                        }"
                    >
                        <div
                            v-for="(embellishments, indx) in quoteList.data
                                .embellishments"
                            :key="indx"
                        >
                            <Embellishments
                                :embellishment="embellishments"
                                @onloadData="onloadData"
                                @selectedEmbellishment="updateEmbellishments"
                            />
                        </div>
                        <div
                            v-if="selectedQuote.ivm_available === false"
                            class="embellishments-overlay"
                        >
                            <p>
                                For selected vendor visualization not avaliable
                            </p>
                        </div>
                    </div>
                </Tab>
                <Tab :title="`Details`">
                    <div class="details">
                        <div
                            v-for="data in selectedQuoteList.info"
                            :key="data.title"
                        >
                            <SeeMore
                                :heading="data.title"
                                :subHeading="data.description"
                                :modularKitchen="true"
                            />
                        </div>
                    </div>
                </Tab>
                <Tab :title="`Quotes`">
                    <div class="quote-list-data">
                        <div
                            v-for="(quote, index) in quoteListData"
                            :key="index"
                            class="quotes"
                            :class="{
                                'selected-quote': selectedQuoteList == quote,
                            }"
                            @click="changeQuoteData('quote', quote)"
                        >
                            <div
                                v-for="(data, index) in quote.attributes"
                                class="quote"
                                :key="index"
                            >
                                <p>{{ data.key }}:{{ data.values }}</p>
                            </div>
                            <div class="quote">
                                <p>
                                    Total Price:-
                                    <span
                                        :class="
                                            requiredDomain == false
                                                ? 'blur'
                                                : ''
                                        "
                                    >
                                        ₹{{
                                            convertToIndianNumberSystem(
                                                quote.price,
                                            )
                                        }}</span
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab :title="`Optional`">
                    <div class="optionals">
                        <template v-for="i in quoteList.data.optional">
                            <div
                                :key="`${i.id}?${i.name}`"
                                class="checkbox-div"
                            >
                                <input
                                    type="checkbox"
                                    :id="`${i.name}`"
                                    :name="`${i.name}`"
                                    :value="i.price"
                                    class="styled-checkbox"
                                    @change="addOptionalPriceToQoute($event, i)"
                                />
                                <span class="optional-label">
                                    <label
                                        :for="`${i.name}`"
                                        class="optional-label"
                                    >
                                        <span>{{ i.name }}</span>
                                    </label>
                                    <span
                                        class="price"
                                        :class="
                                            requiredDomain == false
                                                ? 'blur'
                                                : ''
                                        "
                                        >₹{{
                                            convertToIndianNumberSystem(i.price)
                                        }}</span
                                    >
                                </span>
                            </div>
                        </template>
                    </div>
                </Tab>
            </Tabs>
        </div>

        <div class="comparison-modal">
            <Modal v-if="showComparison" @close="closeModal()" transparent>
                <div class="comparison-options">
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <th>Basic</th>
                                <th>Premium</th>
                                <th>Deluxe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Feature 1</th>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                        />
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <th>Feature 2</th>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                        />
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <th>Feature 3</th>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                                        />
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <th>Feature 4</th>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                        />
                                    </svg>
                                </td>
                                <td>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="icons"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                        />
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>
            <Modal v-if="tryAgainModal" transparent @close="closeTryAgainModal">
                <div class="error-modal">
                    <div class="error-image">
                        <img src="@/assets/images/balloons.png" />
                    </div>
                    <p class="header">
                        Something went wrong
                    </p>
                    <p class="subhead">Please try again</p>
                    <div
                        class="result-button"
                        style="display: flex; justify-content: center;"
                    >
                        <Button
                            class="delete-draft"
                            name="Try again"
                            primary
                            @click.native="tryApiAgain"
                        />
                    </div>
                </div>
            </Modal>
        </div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
// import Checkbox from 'componentsv2/Checkbox'
import Tabs from 'componentsv2/Tabs'
import Tab from 'componentsv2/Tab'
import CustomizeKitchenModule from 'store/modules/CustomizeKitchenPage'
import Embellishments from 'componentsv2/Embellishments'
// import RadioButtonGroup from 'componentsv2/RadioButtonGroup'
import Modal from 'componentsv2/Modal'
import ConvertToPrice from 'mixins/ConvertToPrice'
import Loader from 'componentsv2/Loader'
import axios from 'axios'
import { submitKitchenData } from 'api/ehdApi'
import { getParent } from 'api/brandApi'
import SeeMore from 'components/SeeMore'
import { SNAKE_LOADER } from 'utils/constants'
import Button from 'componentsv2/Button'

export default {
    name: 'KitchenCustomisationPage',
    props: {
        requiredDomain: {
            type: Boolean,
        },
    },
    components: {
        Tabs,
        Tab,
        Embellishments,
        Modal,
        Loader,
        SeeMore,
        Button,
    },
    mixins: [RegisterStoreModule, ConvertToPrice],
    data() {
        return {
            selectedQuote: {},
            selectedImg: null,
            showComparison: false,
            selectedEmbellishments: [],
            loaderShow: false,
            OptionalPrice: 0,
            customerDetails: {},
            selectedOpt: [],
            showKitchenTypes: false,
            kitchenType: '',
            vendorImage: '',
            selectedKitchenType: '',
            showBrandOptions: false,
            quoteListData: {},
            selectedQuoteList: {},
            q: 'HD',
            loaderType: SNAKE_LOADER,
            kitchenInfo: [],
            kitchen_payload: {
                file_type: 'IVM',
                customer_uuid: null,
                scene_uuid: null,
                scene_id: null,
                parent_scene_id: null,
                original_name_in_scene: null,
                new_product_id: null,
                action: 'change_material_2d',
                camera: '',
            },
            cameravalue: [
                'Front_Mid',
                'Front_Top',
                'Back_Mid',
                'Back_Top',
                'Side_Mid',
                'Side_Top',
                'ex1',
            ],
            btnvalue: 'Front_Mid',
            myIndex: 1,
            original_name_in_scene: '',
            new_product_id: '',
            tryAgainModal: false,
            tryAgainObject: {},
        }
    },
    computed: {
        ...mapState({
            quoteList: state => state.CustomizeKitchenPage.quoteList,
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    created() {
        this.registerStoreModule('CustomizeKitchenPage', CustomizeKitchenModule)
        this.fetchQuoteList(this.$route.query.eid).then(response => {
            if (response.responseCode == 200) {
                this.selectedQuote = response.data.quotes[0].vendor_quotes[0]
                this.selectedImg =
                    this.selectedQuote.ivm_available === true
                        ? this.selectedQuote.image_url
                        : this.selectedQuote.line_drawing_image[0]
                this.kitchenType = response.data.quotes[0].quotes_type
                this.vendorImage =
                    response.data.quotes[0].vendor_quotes[0].vendor_image
                this.quoteListData =
                    response.data.quotes[0].vendor_quotes[0].quotes_list
                this.selectedQuoteList =
                    response.data.quotes[0].vendor_quotes[0].quotes_list[0]
            }
        })

        const that = this
        getParent(this.$route.query.sid).then(res => {
            that.customerDetails = res.payloads
        })

        window.addEventListener('message', e => {
            const { data } = e
            if (data.pageLoaded) {
                this.sendMessage()
            }
        })
    },
    methods: {
        ...mapActions({
            fetchQuoteList: 'fetchQuoteList',
        }),
        hideKitchenType() {
            this.showKitchenTypes = false
            this.showBrandOptions = false
        },
        showBrandOptionMenu(bool, kitchen) {
            this.selectedKitchenType = kitchen.quotes_type
                ? kitchen.quotes_type
                : ''
            this.kitchenInfo = kitchen
            this.showBrandOptions = bool
        },
        changeQuoteData(from, kitchen, data) {
            if (from == 'quote') {
                this.selectedQuote = data
                this.selectedQuoteList = data
                this.selectedImg =
                    data.ivm_available === true
                        ? data.image_url
                        : data.line_drawing_image[0]
                this.selectedQuoteList = kitchen
            } else {
                this.quoteList.data.quotes.forEach(quote => {
                    if (kitchen == quote.quotes_type) {
                        quote.vendor_quotes.forEach((vendor, index) => {
                            if (vendor.vendor_id == data) {
                                this.kitchenType = quote.quotes_type
                                this.vendorImage = vendor.vendor_image
                                this.quoteListData = vendor.quotes_list
                                this.selectedQuote = vendor
                                this.selectedQuoteList = vendor.quotes_list[0]
                                this.selectedImg =
                                    vendor.ivm_available === true
                                        ? vendor.image_url
                                        : vendor.line_drawing_image[0]
                            }
                        })
                    }
                })
            }
            this.sendMessage()
        },
        addOptionalPriceToQoute(e, i) {
            if (e.currentTarget.checked == true) {
                this.selectedOpt.push(i)
            } else {
                const index = this.selectedOpt.indexOf(i)
                this.selectedOpt.splice(index, 1)
            }
        },
        closeModal() {
            this.showComparison = false
        },
        closeTryAgainModal() {
            this.tryAgainModal = false
        },
        tryApiAgain() {
            this.tryAgainModal = false
            this.loaderShow = true
            axios
                .post(
                    `https://pensive.siloho.com/rs-script.php`,
                    this.tryAgainObject,
                )
                .then(response => {
                    this.selectedImg =
                        response.data.image_name + `?${new Date().getTime()}`
                    if (response.status == 200) {
                        setTimeout(() => {
                            this.loaderShow = false
                            document.querySelector('body').style.overflow =
                                'auto'
                            if (this.windowWidth > 1100) {
                                var element = document.querySelector(
                                    '.modular-image',
                                )
                                var top = element.offsetTop
                                window.scrollTo(0, top)
                            }
                        }, 2000)
                    } else {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }
                })
        },
        openModal() {
            this.showComparison = true
        },
        nextAction() {
            let obj = {
                scene_id: this.$route.query.sid,
                ehd_id: this.$route.query.eid,
                image: this.selectedImg,
                operation_data: {
                    embellishments: this.selectedEmbellishments,
                    optional: this.selectedOpt,
                    addOns: this.selectedQuoteList.attributes,
                },
            }
            let json_obj = JSON.stringify(obj)
            console.log(this.$route.query.sid, 'scene id')
            submitKitchenData(json_obj).then(response => {
                if (response.responseCode == 200) {
                    this.$router.push({
                        name: 'EHDPage',
                        params: {
                            id: response.payload.ehd_id,
                        },
                        query: { scene: this.$route.query.sid },
                    })
                }
            })
        },
        onloadData(values) {
            if (
                this.selectedEmbellishments.some(
                    option => option.instance_name === values.instance_name,
                )
            ) {
                this.selectedEmbellishments.forEach((item, index) => {
                    if (item.instance_name == values.instance_name) {
                        this.selectedEmbellishments[index].option_details =
                            values.option_details
                    }
                })
            } else {
                this.selectedEmbellishments.push(values)
            }
        },
        updateEmbellishments(values) {
            this.showKitchenTypes = false
            this.onloadData(values)
            this.original_name_in_scene = values.instance_name
            this.new_product_id = values.option_details.option_id
            if (this.q === 'HD') {
                this.loaderShow = true
                document.querySelector('body').style.overflow = 'hidden'
                // const obj = {
                //     file_type: 'IVM',
                //     customer_uuid: this.customerDetails.customer_uuid,
                //     scene_uuid: this.customerDetails.scene_uuid,
                //     scene_id: this.customerDetails.scene_id,
                //     parent_scene_id:
                //         this.selectedQuote.file_name !== undefined
                //             ? this.selectedQuote.file_name
                //             : this.customerDetails.parent_scene_id,
                //     original_product_id: 136,
                //     original_xloc: 0,
                //     original_yloc: 0,
                //     original_zloc: 0,
                //     original_name_in_scene: values.instance_name,
                //     original_degree_turn: 0,
                //     new_product_id: values.option_details.option_id,
                //     ghost: 'false',
                //     category: 'none',
                //     action: 'change_material_2d',
                // }

                //Populating kitchen payload
                this.kitchen_payload.customer_uuid = this.customerDetails.customer_uuid
                this.kitchen_payload.scene_uuid = this.customerDetails.scene_uuid
                this.kitchen_payload.scene_id = this.customerDetails.scene_id
                this.kitchen_payload.parent_scene_id =
                    this.selectedQuote.file_name !== undefined
                        ? this.selectedQuote.file_name
                        : this.customerDetails.parent_scene_id
                this.kitchen_payload.original_name_in_scene = this.original_name_in_scene
                this.kitchen_payload.new_product_id = this.new_product_id
                this.kitchen_payload.action = 'change_material_2d'
                this.tryAgainObject = this.kitchen_payload

                axios
                    .post(
                        `https://pensive.siloho.com/rs-script.php`,
                        this.kitchen_payload,
                    )
                    .then(response => {
                        this.selectedImg =
                            response.data.image_name +
                            `?${new Date().getTime()}`
                        if (response.status == 200) {
                            setTimeout(() => {
                                this.loaderShow = false
                                document.querySelector('body').style.overflow =
                                    'auto'
                                if (this.windowWidth > 1100) {
                                    var element = document.querySelector(
                                        '.modular-image',
                                    )
                                    var top = element.offsetTop
                                    window.scrollTo(0, top)
                                }
                            }, 2000)
                        } else {
                            this.loaderShow = false
                            this.tryAgainModal = true
                        }
                    })
            } else {
                let frame = document.getElementById('low-render-frame')
                const payload = {
                    event: 'KITCHEN_EMBELLISH',
                    material_id: values.option_details.option_id,
                    instance_name: values.instance_name,
                    image_url: values.option_details.option_img,
                }
                frame.contentWindow.postMessage(payload, '*')
                window.addEventListener('message', e => {
                    // if(e.data.loader === false){
                    //     this.loaderShow = e.data.loader
                    //     document.querySelector('body').style.overflow = 'auto'
                    // }
                })
            }
        },

        switchcamera(event) {
            this.loaderShow = true
            var myArray = this.cameravalue
            var myIndex = this.myIndex
            this.btnvalue = myArray[myIndex]
            this.myIndex = (myIndex + 1) % myArray.length

            //Populating the kitchen payload
            this.kitchen_payload.customer_uuid = this.customerDetails.customer_uuid
            this.kitchen_payload.scene_uuid = this.customerDetails.scene_uuid
            this.kitchen_payload.scene_id = this.customerDetails.scene_id
            this.kitchen_payload.parent_scene_id =
                this.selectedQuote.file_name !== undefined
                    ? this.selectedQuote.file_name
                    : this.customerDetails.parent_scene_id
            this.kitchen_payload.original_name_in_scene = this.original_name_in_scene
            this.kitchen_payload.new_product_id = this.new_product_id
            this.kitchen_payload.camera = this.btnvalue
            this.kitchen_payload.action =
                this.kitchen_payload.new_product_id === ''
                    ? 'render_image'
                    : 'change_material_2d_camera_image'
            this.tryAgainObject = this.kitchen_payload
            axios
                .post(
                    `https://pensive.siloho.com/rs-script.php`,
                    this.kitchen_payload,
                )
                .then(response => {
                    this.selectedImg =
                        response.data.image_name + `?${new Date().getTime()}`
                    if (response.status == 200) {
                        setTimeout(() => {
                            this.loaderShow = false
                            document.querySelector('body').style.overflow =
                                'auto'
                            if (this.windowWidth > 1100) {
                                var element = document.querySelector(
                                    '.modular-image',
                                )
                                var top = element.offsetTop
                                window.scrollTo(0, top)
                            }
                        }, 2000)
                    } else {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }
                })
        },
        switchRender(q) {
            this.q = q
            this.sendMessage()
        },
        sendMessage() {
            if (this.q === 'SD') {
                let frame = document.getElementById('low-render-frame')
                const payload = {
                    event: 'KITCHEN_SWITCH',
                    file_name: this.selectedQuote.file_name,
                }
                frame.contentWindow.postMessage(payload, '*')
                // window.addEventListener("message", (e)=>{
                // if(e.data.loader === false){
                //     this.loaderShow = e.data.loader
                //     document.querySelector('body').style.overflow = 'auto'
                // }
                // });
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import './KitchenCustomisationPage.scss';
</style>
