<template>
    <div class="filters-box" v-if="Object.keys(filters).length > 0">
        <div class="header">
            Filter
        </div>
        <div class="filters-container">
            <Tabs :verticalTabs="true" :isselected="0" :productfilter="true">
                <div v-for="filters in filters" :key="filters.filter_type">
                    <Tab
                        v-for="filter in filters.filters"
                        :title="filter.name"
                        :filterTab="true"
                        :key="filter.id"
                    >
                        <template v-if="filter.name == 'Vendors'">
                            <div
                                v-for="letter in letters"
                                :key="letter"
                                class="by-letter"
                            >
                                <p>{{ letter }}</p>
                                <div
                                    v-for="(option, index) in sortedVendors"
                                    :key="'option' + index"
                                >
                                    <CheckBoxData
                                        v-if="
                                            letter ==
                                                option.name[0].toUpperCase()
                                        "
                                        :label="option.name"
                                        :inputValue="option.id"
                                        :onlyCheck="true"
                                        v-model="selectedOptions[filter.name]"
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                v-for="(option, index) in filter.data"
                                :key="'option' + index"
                                class="by-letter"
                            >
                                <CheckBoxData
                                    :label="option.name"
                                    :inputValue="option.id ? option.id : index"
                                    :onlyCheck="true"
                                    :image="option.image ? option.image : ''"
                                    v-model="selectedOptions[filter.name]"
                                />
                                <div
                                    v-if="
                                        filter.name === 'Categories' &&
                                            selectedOptions.Categories.includes(
                                                option.id,
                                            )
                                    "
                                    class="category-options"
                                >
                                    <div
                                        v-for="category in option.categories"
                                        :key="category.id"
                                    >
                                        <CheckBoxData
                                            :label="category.name"
                                            :inputValue="category.id"
                                            :onlyCheck="true"
                                            :image="
                                                option.image ? option.image : ''
                                            "
                                            v-model="selectedSubCategory"
                                            class="category-checkbox"
                                        />
                                        <div
                                            class="subcategory-options"
                                            v-if="
                                                selectedSubCategory.includes(
                                                    category.id,
                                                )
                                            "
                                        >
                                            <CheckBoxData
                                                v-for="sub in category.subcategories"
                                                :key="sub.id"
                                                :label="sub.name"
                                                :inputValue="sub.id"
                                                :onlyCheck="true"
                                                :image="
                                                    option.image
                                                        ? option.image
                                                        : ''
                                                "
                                                v-model="selectedInnerCategory"
                                                class="subcategory-checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Tab>
                </div>
            </Tabs>
        </div>
        <div class="actions">
            <p @click="closeTab">Close</p>
            <p class="apply" @click="updateFilters">Apply</p>
        </div>
    </div>
</template>

<script>
import Checkbox from 'componentsv2/Checkbox'
import CheckBoxData from 'componentsv2/CheckBoxData'
import Tabs from 'componentsv2/Tabs'
import Tab from 'componentsv2/Tab'
export default {
    name: 'ProductFilterMobile',
    components: {
        // Checkbox,
        CheckBoxData,
        Tabs,
        Tab,
    },
    props: {
        filters: {
            required: true,
        },
        selectedVendorID: Number,
        selectedMobileFilters: Array,
        vendors: Array,
        categories: Array,
        prices: Array,
    },
    data() {
        return {
            selectedOptions: {
                Vendors: [],
                Categories: [],
                Color: [],
                Material: [],
                Price: [],
                Shape: [],
                Usage: [],
            },
            selectedFilters: {},
            clearAll: false,
            selectedCategory: [],
            selectedPrices: [],
            selectedSubCategory: [],
            selectedInnerCategory: [],
            selectedVendor: [],
            selectedProperty: {
                material: [],
                usage: [],
                color: [],
            },
            showExtraPriceCount: true,
            showExtraCategoryCount: true,
            showExtraVendorCount: true,
            showMenu: false,
            sortedVendors: {},
            letters: [],
            menuType: '',
        }
    },

    created() {
        console.log(this.selectedMobileFilters, 'selected filter')
        this.sortedVendors = this.filters[0].filters[0].data.sort((a, b) => {
            let comparison = 0
            if (a.name < b.name) {
                comparison = -1
            } else if (a.name > b.name) {
                comparison = 1
            }
            return comparison
        })
        this.letters = [
            ...new Set(
                this.sortedVendors.map(currentValue => {
                    return currentValue.name[0].toUpperCase()
                }),
            ),
        ]

        if (this.selectedMobileFilters.length !== 0) {
            if (this.selectedMobileFilters.vendor) {
                this.selectedVendor = this.selectedMobileFilters.vendor
            }

            this.selectedCategory =
                this.selectedMobileFilters.categorisation.category_groups
                    .length > 0
                    ? this.selectedMobileFilters.categorisation.category_groups
                    : undefined

            if (this.selectedMobileFilters.price.length > 0) {
                this.selectedPrices = this.selectedMobileFilters.price
            }
            if (this.selectedMobileFilters.properties) {
                this.selectedProperty = this.selectedMobileFilters.properties
            }
        }
        if (this.selectedVendorID) {
            this.selectedOptions.Vendors.push(this.selectedVendorID)
        }
    },
    methods: {
        updateFilters() {
            let price = []
            let categorisation = {}
            let property = []
            let obj = {}
            let filts = {}
            console.log(this.selectedOptions, 'options')
            for (const key in this.selectedOptions) {
                switch (key) {
                    case 'Vendors':
                        if (this.selectedOptions[key].length > 0) {
                            this.$set(obj, 'vendor', this.selectedOptions[key])
                        }
                        break
                    case 'Categories':
                        if (this.selectedOptions[key].length > 0) {
                            this.$set(
                                categorisation,
                                'category_groups',
                                this.selectedOptions[key],
                            )
                        }
                        if (this.selectedSubCategory.length > 0) {
                            this.$set(
                                categorisation,
                                'basecategories',
                                this.selectedSubCategory,
                            )
                        }
                        if (this.selectedInnerCategory.length > 0) {
                            this.$set(
                                categorisation,
                                'subcategories',
                                this.selectedInnerCategory,
                            )
                        }

                        if (Object.keys(categorisation).length > 0) {
                            this.$set(obj, 'categorisation', categorisation)
                        }
                        break
                    case 'Price':
                        this.selectedOptions[key].forEach(element => {
                            this.filters.forEach(filtrs => {
                                filtrs.filters.forEach(filter => {
                                    if (filter.name == 'Price') {
                                        filter.data.forEach(elemt => {
                                            if (element == elemt.name) {
                                                price.push({
                                                    max: elemt.max,
                                                    min: elemt.min,
                                                })
                                            }
                                        })
                                    }
                                })
                            })
                        })
                        if (price.length > 0) {
                            this.$set(obj, 'price', price)
                        }
                        break
                    case 'Color':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Color',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    case 'Material':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Material',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    case 'Shape':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Shape',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    case 'Usage':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Usage',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    default:
                        break
                }
            }

            this.$emit('filter', obj)
        },
        closeModal() {
            this.showMenu = false
        },
        clearAllfilters() {
            this.selectedFilters = {}
            this.selectedCategory = []
            this.selectedPrices = []
            this.selectedVendor = []
            this.clearAll = true
            this.$emit('filter', [])
        },
        closeTab() {
            this.$emit('closeFilters')
        },
    },
}
</script>

<style lang="scss" scoped>
@import './ProductFilterMobile.scss';
</style>
