<template>
    <div class="request-product">
        <div class="description">
            Can't find what you are looking for? Tell us the product you like
            and we will procure it for you at the best price.
        </div>
        <div class="send-request" v-if="request">
            <input
                type="text"
                name="productName"
                placeholder="Enter Product Link"
                class="request-textbox"
                v-model="text"
            />

            <Button
                primary
                name="Request"
                :disabled="disabled"
                @click.native="sendRequest"
                class="request-button"
            />
        </div>

        <div class="request-sent" v-else>
            <div class="request-info">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    class="tick-mark"
                >
                    <g data-name="noun_tick mark_2920192">
                        <g data-name="Group 954">
                            <path
                                data-name="Path 1100"
                                d="M11 2a9 9 0 1 0 9 9 9 9 0 0 0-9-9zM9.259 15.722l-3.917-3.843L6.694 10.5 9.247 13 15.3 6.954l1.363 1.364z"
                                transform="translate(-2 -2)"
                                style="fill:#126a18"
                            />
                        </g>
                    </g>
                </svg>
                <span class="submitted-text">
                    'Your request has been submitted'</span
                >
            </div>
            <div class="add-button-wrapper">
                <Button
                    class="add-button"
                    primary
                    name="+ Add Another"
                    @click.native="addAnother"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Button from 'componentsv2/Button'
import { requestProduct } from 'api/userApi'

export default {
    name: 'RequestProduct',
    components: {
        Button,
    },
    data() {
        return {
            request: true,
            text: '',
            disabled: true,
        }
    },
    watch: {
        text(e) {
            var check = e.replace(/\s+/g, '')
            if (check.length != 0) {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },
    },
    methods: {
        sendRequest() {
            this.request = false
            requestProduct(this.text).then(data => {
                this.text = ''
            })
        },
        addAnother() {
            this.request = true
        },
    },
}
</script>
<style lang="scss">
@import './RequestProduct.scss';
</style>
