<template>
    <div class="seemore">
        <h1 class="heading" :class="{ 'heading-margin': modularKitchen }">
            {{ heading }}
        </h1>
        <template v-if="subHeading.length > 2">
            <template v-if="subHeading.length < size">
                <p class="subheading">{{ subHeading }}</p>
            </template>
            <template v-else>
                <template v-if="!seeMoreActivated">
                    <p class="subheading">
                        {{ subHeading.slice(0, size) }}....
                        <span class="more" @click="activateSeeMore(true)"
                            >see more</span
                        >
                    </p>
                </template>
                <template v-if="seeMoreActivated">
                    <p class="subheading">
                        {{ subHeading }}
                        <span class="more" @click="activateSeeMore(false)"
                            >see less</span
                        >
                    </p>
                </template>
            </template>
        </template>
        <template v-else>
            No info available
        </template>
    </div>
</template>

<script>
export default {
    name: 'SeeMore',
    components: {},
    props: {
        heading: {
            type: String,
        },
        subHeading: {
            type: String,
        },
        seeMore: {
            type: Boolean,
        },
        modularKitchen: {
            type: Boolean,
        },
    },
    data() {
        return {
            seeMoreActivated: false,
            size: 100,
        }
    },
    created() {
        if (this.modularKitchen) {
            this.size = 400
        }
    },
    methods: {
        activateSeeMore(bool) {
            this.seeMoreActivated = bool
        },
    },
}
</script>

<style lang="scss">
@import './SeeMore.scss';
</style>
