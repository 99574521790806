import { getBrandList } from 'api/brandApi'
import { getQuoteList } from 'api/brandApi'
import {getKitchenFilters} from 'api/brandApi'
import {getAllKitchenQuotes} from 'api/brandApi'

const CustomizeKitchenPage = {
    state: () => ({
        brandList: [],
        quoteList: [],
        quoteInfo: [],
        kitchenFilters:[],
        kitchenQuotes:[],
    }),

    mutations: {
        setBrandList(state, payload) {
            state.brandList = payload
        },
        setQuoteList(state, payload) {
            state.quoteList = payload
        },
        setQuoteInfo(state, payload) {
            state.quoteInfo = payload
        },
        setkitchenFilters(state, payload) {
            state.kitchenFilters = payload
        },
        setkitchenQuotes(state, payload) {
            state.kitchenQuotes = payload
        },
    },

    getters: {},

    actions: {
        fetchBrandList({ commit }, ehd_id) {
            return getBrandList(ehd_id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setBrandList', payload)
                        return payload
                    }
                })
        },
        fetchQuoteList({ commit }, obj) {
            return getQuoteList(obj)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setQuoteList', payload)
                        return payload
                    }
                })
        },
        fetchKitchenFilters({commit}){
            return getKitchenFilters()
            .then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setkitchenFilters', payload)
                    return payload
                }
            })
        },
        fetchAllKitchenQuotes({commit},obj){
            return getAllKitchenQuotes(obj)
            .then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setkitchenQuotes', payload)
                    return payload
                }
            })
        }

    }
}

export default CustomizeKitchenPage;