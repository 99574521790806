<template>
    <transition name="fullpagemodal">
        <div class="fullpagemodal" @scroll="onScroll">
            <div class="close" @click="handleClose">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    class="close-icon"
                    fill="none"
                    viewBox="0 0 25 24"
                >
                    <path
                        fill="#000"
                        d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                    />
                </svg>
            </div>
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'FullPageModal',
    components: {},
    props: {
        replaceProduct: Boolean,
    },
    data() {
        return {
            //   autoLoad:false,
            prevScroll: 0,
            curScroll: '',
            direction: 0,
            prevDirection: 0,
        }
    },
    mounted() {
        if (this.replaceProduct == true) {
            window.addEventListener('scroll', this.onScroll)
            window.addEventListener('scroll', this.hideHeader)
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            //If last element reached then load api for more products

            if (scrollTop + clientHeight + 1 >= scrollHeight - 1000) {
                this.$emit('loadMoreProducts')
            }

            if (scrollTop > this.prevScroll) {
                //scrolled up
                this.direction = 2
            } else if (scrollTop < this.prevScroll) {
                //scrolled down
                this.direction = 1
            }

            if (this.direction !== this.prevDirection) {
                this.toggleHeader(this.direction, scrollTop)
            }
            this.prevScroll = scrollTop
            if (scrollTop < 25) {
                document.querySelector('.productlist-head').style.paddingTop =
                    '0px'
            } else {
                document.querySelector('.productlist-head').style.paddingTop =
                    '20px'
            }
        },
        //function to add class hide for searchbar and close button
        toggleHeader(direction, curScroll) {
            var header = document.querySelector('.productlist-head')
            var closeIcon = document.querySelector('.close')
            if (direction === 2 && curScroll > 10) {
                //replace 52 with the height of your header in px
                header.classList.add('hide')
                closeIcon.classList.add('hide')
                this.prevDirection = direction
            } else if (direction === 1) {
                header.classList.remove('hide')
                closeIcon.classList.remove('hide')
                this.prevDirection = direction
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './FullPageModal.scss';
</style>
