<template>
    <li class="checkbox">
        <label>
            <input type="checkbox" :value="inputValue" v-model="model" />
            <div :class="onlyCheck ? 'only-check' : 'mark'"></div>
            <img v-if="image" :src="image" />
            <span class="label">{{ label }}</span>
            <div class="sublabel" v-if="sublabel">
                ₹
                {{ convertToIndianNumberSystem(sublabel) }}
            </div>
        </label>
    </li>
</template>

<script>
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'CheckBoxData',
    mixins: [ConvertToPrice],
    props: {
        label: String,
        value: Array,
        inputValue: { type: [Number, String] },
        sublabel: Number,
        onlyCheck: Boolean,
        image: String,
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@import './CheckBoxData.scss';
</style>
