<template>
    <div class="currentproduct">
        <p>Current Product</p>
        <div class="current-details">
            <div class="product-image">
                <img :src="img" :alt="productName" />
            </div>
            <div class="product-details">
                <p class="name">{{ productName }}</p>
                <p
                    class="price"
                    v-if="productPrice != 0"
                    :class="requiredDomain == false ? 'blur' : ''"
                >
                    ₹ {{ convertToIndianNumberSystem(productPrice) }}
                </p>
            </div>
        </div>
        <div class="product-description" v-if="productDescription">
            <p class="des">
                {{ productDescription.slice(0, 75) }}
                <span v-if="productDescription.length > 75">...</span>
            </p>
        </div>
    </div>
</template>

<script>
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'CurrentProduct',
    components: {},
    props: {
        img: {
            required: true,
        },
        productName: {
            type: String,
        },
        productPrice: {
            type: Number,
        },
        // productMRP: {
        //     type: Number,
        // },
        // save: {
        //     type: Number,
        // },
        description: {
            type: String,
        },
        requiredDomain: {
            type: Boolean,
        },
    },
    mixins: [ConvertToPrice],
    data() {
        return {
            productDescription: '',
        }
    },
    created() {
        if (this.description.length > 0) {
            this.productDescription = this.description.replace(
                /(<([^>]+)>)/gi,
                '',
            )
            this.productDescription = this.productDescription.replace(
                /(&nbsp;)+/gi,
                ' ',
            )
        }
    },
}
</script>

<style lang="scss" scoped>
@import './CurrentProduct.scss';
</style>
