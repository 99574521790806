<template>
    <div>
        <FullPageModal
            @close="toggleReplaceProductModal"
            @loadMoreProducts="loadMoreProducts"
            :replaceProduct="true"
            class="replace-product-modal"
        >
            <div v-if="showMobileFilter" style="height: 100%;">
                <ProductFilterMobile
                    v-if="filters && windowWidth < 1100"
                    :filters="filters"
                    @filter="getFilteredProduct"
                    @closeFilters="showMobileFilter = false"
                    :selectedMobileFilters="selectedFilters"
                />
            </div>
            <div class="productlist-layout" v-else>
                <div class="sidebar" v-if="windowWidth > 1100">
                    <div class="sidebar-item">
                        <div
                            class="current-product"
                            v-if="currentProductShow == true"
                        >
                            <router-link
                                :to="{
                                    name: 'ProductDetailsPage',
                                    params: {
                                        id: currentProductID,
                                    },
                                }"
                            >
                                <CurrentProduct
                                    :img="
                                        currentProductImage
                                            ? currentProductImage
                                            : require('@/assets/images/default-product-img.png')
                                    "
                                    :productName="currentProductName"
                                    :requiredDomain="requiredDomain"
                                    :productPrice="currentProductPrice"
                                    :description="currentProductDescription"
                                />
                            </router-link>
                        </div>

                        <div
                            class="filters"
                            v-if="add == false && filters.length != 0"
                        >
                            <ProductFilters
                                :filters="filters"
                                @filter="getFilteredProduct"
                                v-if="windowWidth > 1100 && filters"
                            />
                        </div>
                    </div>
                </div>
                <div class="main" @scroll="onScroll">
                    <template
                        v-if="relatedProducts || similarProducts.length > 0"
                    >
                        <div class="productlist-head">
                            <h1 class="">
                                {{
                                    add == true ? 'Add product' : 'Replace with'
                                }}
                            </h1>

                            <SearchAutoComplete
                                @search="fetchSuggestions"
                                @loadMoreSearchOptions="loadMoreSearchOptions"
                                :suggestionsArr="suggestions"
                            >
                                <template #suggestion="{suggestion}">
                                    <div class="autocomplete-product">
                                        <div class="autocomplete-product-img">
                                            <img :src="suggestion.image" />
                                        </div>
                                        <div
                                            class="autocomplete-product-detail"
                                        >
                                            <p>{{ suggestion.name }}</p>
                                            <div class="autocomplete-category">
                                                <span>
                                                    {{
                                                        suggestion.item_category
                                                    }}
                                                </span>
                                                <template
                                                    v-if="
                                                        suggestion.item_subcategory
                                                    "
                                                >
                                                    <span>
                                                        <svg
                                                            width="25"
                                                            height="24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                                                fill="#000"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span>
                                                        {{
                                                            suggestion.item_subcategory
                                                        }}
                                                    </span>
                                                </template>
                                            </div>
                                        </div>
                                        <div
                                            class="autocomplete-product-action"
                                        >
                                            <template v-if="add == true">
                                                <Button
                                                    class="autocomplete-add"
                                                    name="Add"
                                                    primary
                                                    @click.native="
                                                        $emit(
                                                            'addProductForEHD',
                                                            suggestion,
                                                        )
                                                    "
                                                />
                                            </template>
                                            <template v-else>
                                                <Button
                                                    class="autocomplete-add"
                                                    name="Replace"
                                                    primary
                                                    @click.native="
                                                        $emit(
                                                            'changeProductForEHD',
                                                            suggestion,
                                                        )
                                                    "
                                                />
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </SearchAutoComplete>
                            <div
                                v-if="windowWidth < 1100 && !showMobileFilter"
                                class="filters-mobile"
                                @click="showMobileFilters"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25.385"
                                    height="15"
                                    viewBox="0 0 25.385 15"
                                >
                                    <g
                                        id="Group_2166"
                                        data-name="Group 2166"
                                        transform="translate(-308.125 -113.763)"
                                    >
                                        <g
                                            id="noun-filter-4846914"
                                            transform="translate(308.125 126.77) rotate(-90)"
                                        >
                                            <path
                                                id="Path_2893"
                                                data-name="Path 2893"
                                                class="cls-1"
                                                d="M.75,25.385A.733.733,0,0,1,0,24.669V.716A.733.733,0,0,1,.75,0,.733.733,0,0,1,1.5.716V24.669A.733.733,0,0,1,.75,25.385Z"
                                                transform="translate(0 0)"
                                            />
                                            <path
                                                id="Path_2895"
                                                data-name="Path 2895"
                                                class="cls-1"
                                                d="M.75,25.385a.86.86,0,0,1-.75-.94V.94A.86.86,0,0,1,.75,0,.86.86,0,0,1,1.5.94V24.445A.86.86,0,0,1,.75,25.385Z"
                                                transform="translate(9.129 0)"
                                            />
                                        </g>
                                        <circle
                                            id="Ellipse_613"
                                            data-name="Ellipse 613"
                                            class="cls-1"
                                            cx="3.161"
                                            cy="3.161"
                                            r="3.161"
                                            transform="translate(324.229 113.763)"
                                        />
                                        <circle
                                            id="Ellipse_614"
                                            data-name="Ellipse 614"
                                            class="cls-1"
                                            cx="2.696"
                                            cy="2.696"
                                            r="2.696"
                                            transform="translate(313.735 123.371)"
                                        />
                                    </g>
                                </svg>
                                Filter
                            </div>
                        </div>
                        <div
                            class="filters-applied"
                            v-if="selectedFilters && windowWidth > 1100"
                        >
                            <div
                                v-for="vendor in selectedFilters.vendor"
                                :key="vendor"
                                class="vendors-applied"
                            >
                                <template
                                    v-for="vendors in filters[0].filters[0]
                                        .data"
                                >
                                    <div
                                        v-if="vendor == vendors.id"
                                        :key="vendors.id"
                                        class="check"
                                        @click="undoFilter('vendor', vendor)"
                                    >
                                        <p>{{ vendors.name }}</p>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            fill="#94969f"
                                            viewBox="0 0 25 24"
                                        >
                                            <path
                                                class="cls1"
                                                d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                            />
                                        </svg>
                                    </div>
                                </template>
                            </div>
                            <template v-if="selectedFilters.categorisation">
                                <div
                                    v-for="category in selectedFilters
                                        .categorisation.category_groups"
                                    :key="category"
                                    class="vendors-applied"
                                >
                                    <template
                                        v-for="categories in filters[0]
                                            .filters[1].data"
                                    >
                                        <div
                                            v-if="category == categories.id"
                                            :key="categories.id"
                                            class="check"
                                            @click="
                                                undoFilter('category', category)
                                            "
                                        >
                                            <p>{{ categories.name }}</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                fill="#94969f"
                                                viewBox="0 0 25 24"
                                            >
                                                <path
                                                    class="cls1"
                                                    d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                                />
                                            </svg>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <div
                                v-for="(prop,
                                idx) in selectedFilters.properties"
                                :key="prop"
                                class="vendors-applied"
                            >
                                <div
                                    v-for="(item, index) in prop.values"
                                    :key="item"
                                >
                                    <div
                                        v-for="props in filters[1].filters"
                                        :key="props.id"
                                    >
                                        <template v-for="value in props.data">
                                            <div
                                                v-if="item == value.id"
                                                :key="value.id"
                                                class="check"
                                                @click="
                                                    undoFilter(
                                                        'property',
                                                        idx,
                                                        index,
                                                    )
                                                "
                                            >
                                                <p>{{ value.name }}</p>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="15"
                                                    fill="#94969f"
                                                    viewBox="0 0 25 24"
                                                >
                                                    <path
                                                        class="cls1"
                                                        d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                                    />
                                                </svg>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <template v-if="selectedFilters.price">
                                <div
                                    v-for="(price,
                                    index) in selectedFilters.price"
                                    :key="price"
                                    class="vendors-applied"
                                >
                                    <template
                                        v-for="prices in filters[0].filters[2]
                                            .data"
                                    >
                                        <div
                                            v-if="price.max == prices.max"
                                            :key="prices.id"
                                            class="check"
                                            @click="
                                                undoFilter(
                                                    'price',
                                                    prices,
                                                    index,
                                                )
                                            "
                                        >
                                            <p>{{ prices.name }}</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                fill="#94969f"
                                                viewBox="0 0 25 24"
                                            >
                                                <path
                                                    class="cls1"
                                                    d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                                />
                                            </svg>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                        <div class="cards-container">
                            <template v-if="relatedProducts.length > 0">
                                <div
                                    v-for="product in relatedProducts.payload
                                        .prod_data"
                                    :key="product.id"
                                >
                                    <ProductCards
                                        :threeDavailable="
                                            product.is_3d_available
                                        "
                                        :product-obj="product"
                                        :vendor="product.vendor"
                                        :dimRemarks="product.dim_remarks"
                                        :product-id="product.product_id"
                                        :img="
                                            product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png')
                                        "
                                        :name="product.name"
                                        :current-price="product.item_price"
                                        :requiredDomain="requiredDomain"
                                        :bestSeller="product.bestseller"
                                        :recommended="product.recommended"
                                        @add="
                                            $emit('addProductForEHD', product)
                                        "
                                    />
                                </div>
                            </template>
                            <template v-else-if="similarProducts.length > 0">
                                <div
                                    v-for="product in similarProducts"
                                    :key="product.id"
                                >
                                    <ProductCards
                                        :threeDavailable="
                                            product.is_3d_available
                                        "
                                        :product-obj="product"
                                        replace
                                        :vendor="product.vendor"
                                        :dimRemarks="product.dim_remarks"
                                        :product-id="product.product_id"
                                        :img="
                                            product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png')
                                        "
                                        :name="product.name"
                                        :current-price="product.item_price"
                                        :requiredDomain="requiredDomain"
                                        :bestSeller="product.bestseller"
                                        :recommended="product.recommended"
                                        @replace="
                                            $emit(
                                                'changeProductForEHD',
                                                product,
                                            )
                                        "
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    style="text-align: center;"
                                    class="no-products-found"
                                >
                                    <img
                                        src="@/assets/images/no-results-found.png"
                                    />
                                    <p>No Products Available</p>
                                </div>
                            </template>
                        </div>
                        <div class="loader-container"></div>
                    </template>
                </div>
            </div>
            <div v-if="!showMobileFilter" class="request-product-container">
                <RequestProduct />
            </div>
        </FullPageModal>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CustomisationPageModule from 'store/modules/CustomisationPage'
import ProductListPageModule from 'store/modules/ProductListPage'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import { getProductsBySearch } from 'api/productsApi'
import ConvertToPrice from 'mixins/ConvertToPrice'
import FullPageModal from 'componentsv2/FullPageModal'
import CurrentProduct from 'componentsv2/CurrentProduct'
import ProductCards from 'componentsv2/ProductCards'
import SearchAutoComplete from 'componentsv2/SearchAutoComplete'
import RequestProduct from 'componentsv2/RequestProduct'
import Button from 'componentsv2/Button'
import ProductFilters from 'componentsv2/ProductFilters'
import ProductFilterMobile from 'componentsv2/ProductFilterMobile'
export default {
    name: 'ReplaceProductModal',
    components: {
        FullPageModal,
        CurrentProduct,
        ProductCards,
        RequestProduct,
        SearchAutoComplete,
        Button,
        ProductFilters,
        ProductFilterMobile,
    },
    props: {
        currentProductShow: Boolean,
        currentProductID: Number,
        currentProductImage: String,
        currentProductName: String,
        currentProductPrice: Number,
        currentProductDescription: String,
        requiredDomain: Boolean,
        currentSceneId: Number,
        add: Boolean,
    },
    mixins: [RegisterStoreModule, ConvertToPrice],
    data() {
        return {
            filter_str: '',
            pageNumber: 1,
            totalNumberOfPages: '',
            similarProducts: null,
            suggestions: [],
            //paging for search products
            searchNumber: 1,
            totalNumberOfSearchPages: '',
            searchingActive: true,
            autoLoad: true,
            showMobileFilter: false,
            prevScroll: 0,
            selectedFilters: {},
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.AppModule.windowWidth,
            filters: state => state.ProductListPage.filters_data,
            relatedProducts: state => state.CustomisationPage.relatedProducts,
            productsList: state => state.ProductListPage.productsList,
        }),
    },
    mounted() {
        window.addEventListener('scroll', this.hideHeader)
    },
    created() {
        this.registerStoreModule('CustomisationPage', CustomisationPageModule)
        this.registerStoreModule('ProductListPage', ProductListPageModule)
        document.querySelector('body').style.overflow = 'hidden'
        if (this.add == false) {
            this.fetchFilter(this.currentProductID)

            this.fetchAllProducts({
                filters: [],
                page: this.pageNumber,
                product_id: this.currentProductID,
                size: 20,
            }).then(response => {
                if (response.responseCode == 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.products
                    this.$emit('closeLoader')
                }
            })
        } else {
            this.fetchProducts(0)
        }
    },
    methods: {
        ...mapActions({
            fetchFilter: 'fetchFilter',
            fetchProducts: 'fetchProducts',
            updateRelatedProducts: 'updateRelatedProducts',
            fetchAllProducts: 'fetchAllProducts',
        }),
        toggleReplaceProductModal() {
            this.$emit('toggleReplaceProductModal')
        },
        showMobileFilters() {
            this.showMobileFilter = !this.showMobileFilter
        },
        onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            //If last element reached then load api for more products

            if (scrollTop + clientHeight + 1 >= scrollHeight - 1000) {
                this.loadMoreProducts()
            }
            if (scrollTop > this.prevScroll) {
                //scrolled up
                this.direction = 2
            } else if (scrollTop < this.prevScroll) {
                //scrolled down
                this.direction = 1
            }

            if (this.direction !== this.prevDirection) {
                this.toggleHeader(this.direction, scrollTop)
            }
            this.prevScroll = scrollTop
            if (scrollTop < 25) {
                document.querySelector('.productlist-head').style.paddingTop =
                    '0px'
            } else {
                document.querySelector('.productlist-head').style.paddingTop =
                    '15px'
            }
        },
        //function to add class hide for searchbar and close button
        toggleHeader(direction, curScroll) {
            var header = document.querySelector('.productlist-head')
            var closeIcon = document.querySelector('.close')
            if (direction === 2 && curScroll > 10) {
                //replace 52 with the height of your header in px
                header.classList.add('hide')
                closeIcon.classList.add('hide')
                this.prevDirection = direction
            } else if (direction === 1) {
                header.classList.remove('hide')
                closeIcon.classList.remove('hide')
                this.prevDirection = direction
            }
        },
        undoFilter(type, value, id) {
            switch (type) {
                case 'vendor':
                    this.selectedFilters.vendor.forEach((element, index) => {
                        if (element == value) {
                            this.selectedFilters.vendor.splice(index, 1)
                        }
                    })
                    break
                case 'category':
                    this.selectedFilters.categorisation.category_groups.forEach(
                        (element, index) => {
                            if (element == value) {
                                this.selectedFilters.categorisation.category_groups.splice(
                                    index,
                                    1,
                                )
                            }
                        },
                    )
                    break
                case 'price':
                    this.selectedFilters.price.splice(id, 1)
                    this.selectedFilters.price.splice(id, 1)

                    break
                case 'property':
                    this.selectedFilters.properties.forEach(
                        (element, index) => {
                            if (index === value) {
                                element.values.forEach((ele, idx) => {
                                    if (idx == id) {
                                        this.selectedFilters.properties[
                                            index
                                        ].values.splice(id, 1)
                                    }
                                })
                            }
                        },
                    )
                    break
            }
        },
        getFilteredProduct(filter) {
            this.selectedFilters = filter
            this.showMobileFilter = false
            this.pageNumber = 1
            this.fetchAllProducts({
                filters: filter,
                product_id: this.currentProductID,
                page: this.pageNumber,
                size: 20,
            }).then(response => {
                if (response.responseCode == 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.products
                }
            })
        },
        loadMoreProducts() {
            const spinner = `<div class="spinner"></div>`
            let loaderContainer = document.querySelector('.loader-container')
            if (
                this.pageNumber < this.totalNumberOfPages &&
                this.autoLoad == true
            ) {
                this.autoLoad = false
                this.pageNumber++
                loaderContainer.innerHTML += spinner
                this.fetchAllProducts({
                    filters: this.selectedFilters,
                    product_id: this.currentProductID,
                    page: this.pageNumber,
                    size: 20,
                }).then(response => {
                    if (response.responseCode == 200) {
                        this.totalNumberOfPages = response.payload.total_page
                        loaderContainer.removeChild(
                            document.querySelector('.spinner'),
                        )
                        response.payload.products.forEach(product => {
                            this.similarProducts.push(product)
                        })
                        this.autoLoad = true
                    }
                })
            }
        },
        fetchSuggestions(query) {
            if (query.length > 2) {
                this.suggestions = []
                this.searchNumber = 1
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    this.suggestions = response.furnish_data
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },
        loadMoreSearchOptions(query) {
            if (
                this.searchNumber < this.totalNumberOfSearchPages &&
                this.searchingActive == true
            ) {
                this.searchingActive = false
                this.searchNumber++
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    response.furnish_data.forEach(product => {
                        this.suggestions.push(product)
                    })
                    this.searchingActive = true
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import './ReplaceProductModal.scss';
</style>
