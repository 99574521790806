import Api from 'utils/Api'

export function getBrandList(ehd_id) {
    return Api
        .get(`CADVisualization/get_kitchen_vendors/?ehd_id=${ehd_id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
// export function getQuoteList(obj) {
//     return Api
//         .get(`CADVisualization/get_kitchen_quotes_details/?vendor_id=${obj.vendor_id}&ehd_id=${obj.ehd_id}`)
//         .then(response => {
//             return response.data
//         })
//         .catch(e => {
//             return { status: e.response.status, data: e.response.data }
//         })
// }
export function getQuoteList(id) {
    return Api
        .get(`v2/CADVisualization/get_kitchen_quotes?ehd_id=${id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}


export function getParent(scene_id) {
    return Api
        .get(`CADVisualization/get_customer_details_for_kitchen?scene_id=${scene_id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function getKitchenFilters() {
    return Api
        .get(`v2/CADVisualization/get_kitchen_filters`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function getAllKitchenQuotes(obj) {
    return Api
        .post(`v2/CADVisualization/get_all_kitchen_quotes/`,obj)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}