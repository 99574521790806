<template>
    <div class="embellishment-container">
        <div class="option-header">
            <p v-if="embellishment.title == 'Kitchen Tiles'">
                Backsplash Tiles
            </p>
            <p v-else-if="embellishment.title == 'Kitchen Unit'">
                Base Cabinets
            </p>
            <p v-else>{{ embellishment.title }}</p>
            <p class="viewall" @click="openModal()">View all</p>
        </div>
        <div class="option-list">
            <!-- <div v-for="(img) in embellishment.options.slice(0, 3)" :key="img.option_id"  class="imagelist">
                    <img :src="img.option_img" @click="chosenImage(img)" :class="{selected: selectedImage.option_id == img.option_id}"/>
                    <div :class="{selected_image: selectedImage.option_id == img.option_id}"></div>
                </div> -->
            <div
                v-for="(img, index) in displayArray"
                :key="img.option_id"
                class="imagelist"
            >
                <img
                    :src="
                        img.option_img
                            ? img.option_img
                            : require('@/assets/' + defaultIcon)
                    "
                    @click="chosenImage(img, index)"
                    :class="{
                        selected:
                            selectedImage.option_id == img.option_id &&
                            index == selectedPosition,
                    }"
                />
                <div
                    :class="{
                        selected_image:
                            selectedImage.option_id == img.option_id &&
                            index == selectedPosition,
                    }"
                ></div>
            </div>
        </div>
        <ReplaceProductModal
            v-if="showOptions"
            :currentProductID="selectedImage.option_id"
            :currentProductImage="
                selectedImage.option_img
                    ? selectedImage.option_img
                    : require('@/assets/images/default-product-img.png')
            "
            :currentProductName="selectedImage.option_name"
            :requiredDomain="true"
            :currentProductPrice="
                selectedImage.item_price
                    ? parseInt(selectedImage.item_price)
                    : 0
            "
            :currentProductShow="true"
            :currentProductDescription="
                selectedImage.description ? selectedImage.description : ''
            "
            :currentSceneId="Number(this.$route.query.sid)"
            @toggleReplaceProductModal="toggleReplaceProductModal"
            @changeProductForEHD="changeSelection"
        />
        <!-- <div class="embellishment-option-modal">
                <Modal
                    v-if="showOptions"
                    @close="closeModal()"
                    transparent
                >
                    <div class="embellishment-options"> Other options
                        <div class="other-options">
                             <div v-for="(img) in extraOptions" :key="img.option_id"  class="imagelist">
                                <img :src="img.option_img? img.option_img: require('@/assets/' + defaultIcon)" @click="replaceImages(img)" :class="{selected:replace.option_id== img.option_id}" />
                                <div :class="{selected_image: replace.option_id == img.option_id}" ></div>
                            </div>
                        </div>
                        <Button
                            primary
                            name="Update"
                            class="change-button"
                            :handleClick="changeSelection"
                        >
                        </Button>
                    </div>                
                </Modal>
            </div> -->
    </div>
</template>
<script>
import Modal from 'componentsv2/Modal'
import Button from 'componentsv2/Button'
import ReplaceProductModal from 'componentsv2/ReplaceProductModal'
export default {
    name: 'Embellishments',
    components: {
        // Modal,
        // Button
        ReplaceProductModal,
    },
    props: {
        embellishment: Object,
        requiredDomain: Boolean,
    },
    data() {
        return {
            selectedImage: this.embellishment.options[1],
            replace: this.embellishment.options[3],
            showOptions: false,
            displayArray: this.embellishment.options.slice(0, 3),
            extraOptions: this.embellishment.options.slice(3),
            defaultIcon: null,
            selectedPosition: 1,
        }
    },
    created() {
        switch (this.embellishment.title) {
            case 'Shutters':
                this.defaultIcon = 'images/default-shutter.png'
                break
            case 'Wall paint':
                this.defaultIcon = 'images/default-wallpaint.png'
                break
            case 'Flooring':
                this.defaultIcon = 'images/default-flooring.png'
                break
            case 'Kitchen Tiles':
                this.defaultIcon = 'images/default-tiles.png'
                break
            case 'Kitchen Unit':
                this.defaultIcon = 'images/default-unit.png'
                break
        }
    },
    computed: {},
    mounted() {
        this.$emit('onloadData', {
            option_details: this.selectedImage,
            instance_name: this.embellishment.instance_name,
        })
    },
    methods: {
        closeModal() {
            this.showOptions = false
        },
        openModal() {
            this.showOptions = true
        },
        toggleReplaceProductModal() {
            this.showOptions = !this.showOptions

            if (this.showOptions == true) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
            }
        },
        chosenImage(imgs, index) {
            this.selectedImage = imgs
            this.selectedPosition = index
            this.$emit('selectedEmbellishment', {
                option_details: this.selectedImage,
                instance_name: this.embellishment.instance_name,
            })
        },
        replaceImages(img) {
            this.replace = img
        },
        changeSelection(product) {
            let obj = {
                option_id: product.product_id,
                option_img: product.image,
                option_name: product.name,
            }
            this.displayArray[this.selectedPosition] = obj
            this.selectedImage = obj
            this.$emit('selectedEmbellishment', {
                option_details: this.selectedImage,
                instance_name: this.embellishment.instance_name,
            })
            // this.extraOptions.forEach((item, index) => {
            //     if (item.option_id == this.replace.option_id) {
            //         this.extraOptions[index] = this.selectedImage
            //         this.selectedImage = this.replace
            //         this.replace = this.extraOptions[index]
            //         this.$emit('selectedEmbellishment', {
            //             option_details: this.selectedImage,
            //             instance_name: this.embellishment.instance_name,
            //         })
            //     }
            // })
            this.toggleReplaceProductModal()
        },
    },
}
</script>
<style lang="scss" scoped>
@import './Embellishments.scss';
</style>
