var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"embellishment-container"},[_c('div',{staticClass:"option-header"},[(_vm.embellishment.title == 'Kitchen Tiles')?_c('p',[_vm._v(" Backsplash Tiles ")]):(_vm.embellishment.title == 'Kitchen Unit')?_c('p',[_vm._v(" Base Cabinets ")]):_c('p',[_vm._v(_vm._s(_vm.embellishment.title))]),_c('p',{staticClass:"viewall",on:{"click":function($event){return _vm.openModal()}}},[_vm._v("View all")])]),_c('div',{staticClass:"option-list"},_vm._l((_vm.displayArray),function(img,index){return _c('div',{key:img.option_id,staticClass:"imagelist"},[_c('img',{class:{
                    selected:
                        _vm.selectedImage.option_id == img.option_id &&
                        index == _vm.selectedPosition,
                },attrs:{"src":img.option_img
                        ? img.option_img
                        : require('@/assets/' + _vm.defaultIcon)},on:{"click":function($event){return _vm.chosenImage(img, index)}}}),_c('div',{class:{
                    selected_image:
                        _vm.selectedImage.option_id == img.option_id &&
                        index == _vm.selectedPosition,
                }})])}),0),(_vm.showOptions)?_c('ReplaceProductModal',{attrs:{"currentProductID":_vm.selectedImage.option_id,"currentProductImage":_vm.selectedImage.option_img
                ? _vm.selectedImage.option_img
                : require('@/assets/images/default-product-img.png'),"currentProductName":_vm.selectedImage.option_name,"requiredDomain":true,"currentProductPrice":_vm.selectedImage.item_price
                ? parseInt(_vm.selectedImage.item_price)
                : 0,"currentProductShow":true,"currentProductDescription":_vm.selectedImage.description ? _vm.selectedImage.description : '',"currentSceneId":Number(this.$route.query.sid)},on:{"toggleReplaceProductModal":_vm.toggleReplaceProductModal,"changeProductForEHD":_vm.changeSelection}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }