var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FullPageModal',{staticClass:"replace-product-modal",attrs:{"replaceProduct":true},on:{"close":_vm.toggleReplaceProductModal,"loadMoreProducts":_vm.loadMoreProducts}},[(_vm.showMobileFilter)?_c('div',{staticStyle:{"height":"100%"}},[(_vm.filters && _vm.windowWidth < 1100)?_c('ProductFilterMobile',{attrs:{"filters":_vm.filters,"selectedMobileFilters":_vm.selectedFilters},on:{"filter":_vm.getFilteredProduct,"closeFilters":function($event){_vm.showMobileFilter = false}}}):_vm._e()],1):_c('div',{staticClass:"productlist-layout"},[(_vm.windowWidth > 1100)?_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-item"},[(_vm.currentProductShow == true)?_c('div',{staticClass:"current-product"},[_c('router-link',{attrs:{"to":{
                                name: 'ProductDetailsPage',
                                params: {
                                    id: _vm.currentProductID,
                                },
                            }}},[_c('CurrentProduct',{attrs:{"img":_vm.currentProductImage
                                        ? _vm.currentProductImage
                                        : require('@/assets/images/default-product-img.png'),"productName":_vm.currentProductName,"requiredDomain":_vm.requiredDomain,"productPrice":_vm.currentProductPrice,"description":_vm.currentProductDescription}})],1)],1):_vm._e(),(_vm.add == false && _vm.filters.length != 0)?_c('div',{staticClass:"filters"},[(_vm.windowWidth > 1100 && _vm.filters)?_c('ProductFilters',{attrs:{"filters":_vm.filters},on:{"filter":_vm.getFilteredProduct}}):_vm._e()],1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"main",on:{"scroll":_vm.onScroll}},[(_vm.relatedProducts || _vm.similarProducts.length > 0)?[_c('div',{staticClass:"productlist-head"},[_c('h1',{},[_vm._v(" "+_vm._s(_vm.add == true ? 'Add product' : 'Replace with')+" ")]),_c('SearchAutoComplete',{attrs:{"suggestionsArr":_vm.suggestions},on:{"search":_vm.fetchSuggestions,"loadMoreSearchOptions":_vm.loadMoreSearchOptions},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
                                        var suggestion = ref.suggestion;
return [_c('div',{staticClass:"autocomplete-product"},[_c('div',{staticClass:"autocomplete-product-img"},[_c('img',{attrs:{"src":suggestion.image}})]),_c('div',{staticClass:"autocomplete-product-detail"},[_c('p',[_vm._v(_vm._s(suggestion.name))]),_c('div',{staticClass:"autocomplete-category"},[_c('span',[_vm._v(" "+_vm._s(suggestion.item_category)+" ")]),(
                                                    suggestion.item_subcategory
                                                )?[_c('span',[_c('svg',{attrs:{"width":"25","height":"24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z","fill":"#000"}})])]),_c('span',[_vm._v(" "+_vm._s(suggestion.item_subcategory)+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"autocomplete-product-action"},[(_vm.add == true)?[_c('Button',{staticClass:"autocomplete-add",attrs:{"name":"Add","primary":""},nativeOn:{"click":function($event){return _vm.$emit(
                                                        'addProductForEHD',
                                                        suggestion
                                                    )}}})]:[_c('Button',{staticClass:"autocomplete-add",attrs:{"name":"Replace","primary":""},nativeOn:{"click":function($event){return _vm.$emit(
                                                        'changeProductForEHD',
                                                        suggestion
                                                    )}}})]],2)])]}}],null,false,2796052133)}),(_vm.windowWidth < 1100 && !_vm.showMobileFilter)?_c('div',{staticClass:"filters-mobile",on:{"click":_vm.showMobileFilters}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25.385","height":"15","viewBox":"0 0 25.385 15"}},[_c('g',{attrs:{"id":"Group_2166","data-name":"Group 2166","transform":"translate(-308.125 -113.763)"}},[_c('g',{attrs:{"id":"noun-filter-4846914","transform":"translate(308.125 126.77) rotate(-90)"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Path_2893","data-name":"Path 2893","d":"M.75,25.385A.733.733,0,0,1,0,24.669V.716A.733.733,0,0,1,.75,0,.733.733,0,0,1,1.5.716V24.669A.733.733,0,0,1,.75,25.385Z","transform":"translate(0 0)"}}),_c('path',{staticClass:"cls-1",attrs:{"id":"Path_2895","data-name":"Path 2895","d":"M.75,25.385a.86.86,0,0,1-.75-.94V.94A.86.86,0,0,1,.75,0,.86.86,0,0,1,1.5.94V24.445A.86.86,0,0,1,.75,25.385Z","transform":"translate(9.129 0)"}})]),_c('circle',{staticClass:"cls-1",attrs:{"id":"Ellipse_613","data-name":"Ellipse 613","cx":"3.161","cy":"3.161","r":"3.161","transform":"translate(324.229 113.763)"}}),_c('circle',{staticClass:"cls-1",attrs:{"id":"Ellipse_614","data-name":"Ellipse 614","cx":"2.696","cy":"2.696","r":"2.696","transform":"translate(313.735 123.371)"}})])]),_vm._v(" Filter ")]):_vm._e()],1),(_vm.selectedFilters && _vm.windowWidth > 1100)?_c('div',{staticClass:"filters-applied"},[_vm._l((_vm.selectedFilters.vendor),function(vendor){return _c('div',{key:vendor,staticClass:"vendors-applied"},[_vm._l((_vm.filters[0].filters[0]
                                    .data),function(vendors){return [(vendor == vendors.id)?_c('div',{key:vendors.id,staticClass:"check",on:{"click":function($event){return _vm.undoFilter('vendor', vendor)}}},[_c('p',[_vm._v(_vm._s(vendors.name))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","fill":"#94969f","viewBox":"0 0 25 24"}},[_c('path',{staticClass:"cls1",attrs:{"d":"M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"}})])]):_vm._e()]})],2)}),(_vm.selectedFilters.categorisation)?_vm._l((_vm.selectedFilters
                                    .categorisation.category_groups),function(category){return _c('div',{key:category,staticClass:"vendors-applied"},[_vm._l((_vm.filters[0]
                                        .filters[1].data),function(categories){return [(category == categories.id)?_c('div',{key:categories.id,staticClass:"check",on:{"click":function($event){return _vm.undoFilter('category', category)}}},[_c('p',[_vm._v(_vm._s(categories.name))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","fill":"#94969f","viewBox":"0 0 25 24"}},[_c('path',{staticClass:"cls1",attrs:{"d":"M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"}})])]):_vm._e()]})],2)}):_vm._e(),_vm._l((_vm.selectedFilters.properties),function(prop,idx){return _c('div',{key:prop,staticClass:"vendors-applied"},_vm._l((prop.values),function(item,index){return _c('div',{key:item},_vm._l((_vm.filters[1].filters),function(props){return _c('div',{key:props.id},[_vm._l((props.data),function(value){return [(item == value.id)?_c('div',{key:value.id,staticClass:"check",on:{"click":function($event){return _vm.undoFilter(
                                                    'property',
                                                    idx,
                                                    index
                                                )}}},[_c('p',[_vm._v(_vm._s(value.name))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","fill":"#94969f","viewBox":"0 0 25 24"}},[_c('path',{staticClass:"cls1",attrs:{"d":"M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"}})])]):_vm._e()]})],2)}),0)}),0)}),(_vm.selectedFilters.price)?_vm._l((_vm.selectedFilters.price),function(price,index){return _c('div',{key:price,staticClass:"vendors-applied"},[_vm._l((_vm.filters[0].filters[2]
                                        .data),function(prices){return [(price.max == prices.max)?_c('div',{key:prices.id,staticClass:"check",on:{"click":function($event){return _vm.undoFilter(
                                                'price',
                                                prices,
                                                index
                                            )}}},[_c('p',[_vm._v(_vm._s(prices.name))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","fill":"#94969f","viewBox":"0 0 25 24"}},[_c('path',{staticClass:"cls1",attrs:{"d":"M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"}})])]):_vm._e()]})],2)}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"cards-container"},[(_vm.relatedProducts.length > 0)?_vm._l((_vm.relatedProducts.payload
                                    .prod_data),function(product){return _c('div',{key:product.id},[_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                            ? product.image_meta.n_image
                                            : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"add":function($event){return _vm.$emit('addProductForEHD', product)}}})],1)}):(_vm.similarProducts.length > 0)?_vm._l((_vm.similarProducts),function(product){return _c('div',{key:product.id},[_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"replace":"","vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                            ? product.image_meta.n_image
                                            : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"replace":function($event){return _vm.$emit(
                                            'changeProductForEHD',
                                            product
                                        )}}})],1)}):[_c('div',{staticClass:"no-products-found",staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/images/no-results-found.png")}}),_c('p',[_vm._v("No Products Available")])])]],2),_c('div',{staticClass:"loader-container"})]:_vm._e()],2)]),(!_vm.showMobileFilter)?_c('div',{staticClass:"request-product-container"},[_c('RequestProduct')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }