<template>
    <div class="filters-box">
        <div class="header">
            <p>Filter</p>
            <p class="clear-all" @click="clearAllfilters">Clear All</p>
        </div>

        <div class="filters-container">
            <Tabs v-if="!showMenu" :isselected="0" :fullWidth="true">
                <Tab
                    v-for="tab in filters"
                    :key="tab.filter_type"
                    :title="
                        tab.filter_type == 'Visual'
                            ? tab.filter_type
                            : 'Products'
                    "
                >
                    <div
                        class="filter-checkbox"
                        v-for="filters in tab.filters"
                        :key="filters.name"
                        ref="checkboxesContainer"
                    >
                        <div class="filter-name">
                            {{ filters.name }}
                        </div>
                        <div class="checkbox-list">
                            <div
                                v-for="(filter, index) in filters.data.slice(
                                    0,
                                    4,
                                )"
                                :key="index"
                            >
                                <CheckBoxData
                                    :label="filter.name"
                                    :inputValue="
                                        filter.id ? filter.id : filter.name
                                    "
                                    :image="filter.image ? filter.image : ''"
                                    v-model="selectedOptions[filters.name]"
                                />
                                <div
                                    v-if="
                                        filters.name === 'Categories' &&
                                            selectedOptions.Categories.includes(
                                                filter.id,
                                            )
                                    "
                                    class="category-options"
                                >
                                    <div
                                        v-for="category in filter.categories"
                                        :key="category.id"
                                    >
                                        <CheckBoxData
                                            :label="category.name"
                                            :inputValue="category.id"
                                            v-model="selectedSubCategory"
                                            class="category-checkbox"
                                        />
                                        <div
                                            class="subcategory-options"
                                            v-if="
                                                selectedSubCategory.includes(
                                                    category.id,
                                                )
                                            "
                                        >
                                            <CheckBoxData
                                                v-for="sub in category.subcategories"
                                                :key="sub.id"
                                                :label="sub.name"
                                                :inputValue="sub.id"
                                                v-model="selectedInnerCategory"
                                                class="subcategory-checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    filters.data.slice(4).length > 0 &&
                                        showMoreOptions[filters.name] == true
                                "
                                class="products-count"
                                @click="
                                    showMoreItems(
                                        filters.name,
                                        filters.data.slice(4).length,
                                        filters.data,
                                    )
                                "
                            >
                                + {{ filters.data.slice(4).length }} more
                            </div>
                            <template
                                v-if="showMoreOptions[filters.name] == false"
                            >
                                <div
                                    v-for="(filter,
                                    index) in filters.data.slice(4)"
                                    :key="index"
                                >
                                    <CheckBoxData
                                        :label="filter.name"
                                        :inputValue="
                                            filter.id ? filter.id : filter.name
                                        "
                                        :image="filter.image"
                                        v-model="selectedOptions[filters.name]"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </Tab>
            </Tabs>
            <div v-else class="option-modal">
                <div class="close-btn" @click="closeModal">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        fill="#d3d3d3"
                        viewBox="0 0 25 24"
                    >
                        <path
                            class="cls1"
                            d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                        />
                    </svg>
                </div>
                <div class="list">
                    <template v-if="menuType === 'Vendors'">
                        <div
                            v-for="letter in letters"
                            :key="letter"
                            class="by-letter"
                        >
                            {{ letter }}
                            <div
                                v-for="(option, index) in sortedVendors"
                                :key="'option' + index"
                                style="margin-bottom:7px;"
                            >
                                <CheckBoxData
                                    v-if="
                                        letter == option.name[0].toUpperCase()
                                    "
                                    :label="option.name"
                                    :inputValue="option.id"
                                    ref="checkboxesContainer"
                                    @input="
                                        toggleSelected(
                                            menuType,
                                            option.name,
                                            option.id,
                                        )
                                    "
                                    v-model="selectedOptions[menuType]"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div
                            v-for="(option, index) in modalData"
                            :key="'option' + index"
                            class="by-letter"
                        >
                            <CheckBoxData
                                :label="option.name"
                                :inputValue="option.name"
                                :image="option.image"
                                ref="checkboxesContainer"
                                @input="
                                    toggleSelected(
                                        menuType.toLowerCase(),
                                        option.name,
                                        option.id,
                                    )
                                "
                            />
                            <!-- <template v-if="menuType === 'Categories'">
                                <div
                                    v-if="selectedOptions.includes(option.name)"
                                    class="category-options"
                                >
                                    <div
                                        v-for="category in option.categories"
                                        :key="category.id"
                                    >
                                        <CheckBoxData
                                            :label="category.name"
                                            :inputValue="category.id"
                                            v-model="selectedOptions.Categories"
                                            class="category-checkbox"
                                        />
                                        <div
                                            class="subcategory-options"
                                            v-if="
                                                selectedSubCategory.includes(
                                                    category.id,
                                                )
                                            "
                                        >
                                            <CheckBoxData
                                                v-for="sub in category.subcategories"
                                                :key="sub.id"
                                                :label="sub.name"
                                                :inputValue="sub.id"
                                                v-model="selectedInnerCategory"
                                                class="subcategory-checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template> -->
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from 'componentsv2/Checkbox'
import CheckBoxData from 'componentsv2/CheckBoxData'
import Tabs from 'componentsv2/Tabs'
import Tab from 'componentsv2/Tab'
export default {
    name: 'ProductFilters',
    components: {
        // Checkbox,
        CheckBoxData,
        Tabs,
        Tab,
    },
    props: {
        filters: {
            required: true,
        },
        selectedVendorID: Number,
        vendors: Array,
        categories: Array,
        prices: Array,
    },
    data() {
        return {
            selectedOptions: {
                Vendors: [],
                Categories: [],
                Color: [],
                Material: [],
                Price: [],
                Shape: [],
                Usage: [],
            },
            showMoreOptions: {
                Vendors: true,
                Categories: true,
                Color: true,
                Material: true,
                Price: true,
                Shape: true,
                Usage: true,
            },
            selectedSubCategory: [],
            selectedInnerCategory: [],
            selectedFilters: {},
            clearAll: false,
            showMenu: false,
            sortedVendors: {},
            letters: [],
            menuType: '',
            modalData: {},
        }
    },
    watch: {
        selectedOptions: {
            handler(val) {
                this.updateFilters()
            },
            deep: true,
        },
        deep: true,
        selectedInnerCategory: function(e) {
            this.updateFilters()
        },
        selectedSubCategory: function(e) {
            this.updateFilters()
        },
    },
    created() {
        this.filters.forEach(filters => {
            filters.filters.forEach(filter => {
                if (filter.name === 'Vendors') {
                    this.sortedVendors = filter.data.sort((a, b) => {
                        let comparison = 0
                        if (a.name < b.name) {
                            comparison = -1
                        } else if (a.name > b.name) {
                            comparison = 1
                        }
                        return comparison
                    })
                }
            })
        })
        this.letters = [
            ...new Set(
                this.sortedVendors.map(currentValue => {
                    return currentValue.name[0].toUpperCase()
                }),
            ),
        ]
        if (this.selectedVendorID) {
            this.selectedOptions.Vendors.push(this.selectedVendorID)
        }
    },
    methods: {
        toggleSelected(filterName, optionValue, optionId) {
            if (
                !this.selectedOptions[filterName.toLowerCase()].includes(
                    optionId,
                )
            ) {
                this.selectedOptions[filterName.toLowerCase()].push(optionId)
            } else {
                const index = this.selectedOptions[
                    filterName.toLowerCase()
                ].includes(optionId)
                this.selectedOptions[filterName.toLowerCase()].splice(index, 1)
            }
        },
        updateFilters() {
            let price = []
            let categorisation = {}
            let property = []
            let obj = {}
            let filts = {}
            for (const key in this.selectedOptions) {
                switch (key) {
                    case 'Vendors':
                        if (this.selectedOptions[key].length > 0) {
                            this.$set(obj, 'vendor', this.selectedOptions[key])
                        }
                        break
                    case 'Categories':
                        if (this.selectedOptions[key].length > 0) {
                            this.$set(
                                categorisation,
                                'category_groups',
                                this.selectedOptions[key],
                            )
                        }
                        if (this.selectedSubCategory.length > 0) {
                            this.$set(
                                categorisation,
                                'basecategories',
                                this.selectedSubCategory,
                            )
                        }
                        if (this.selectedInnerCategory.length > 0) {
                            this.$set(
                                categorisation,
                                'subcategories',
                                this.selectedInnerCategory,
                            )
                        }

                        if (Object.keys(categorisation).length > 0) {
                            this.$set(obj, 'categorisation', categorisation)
                        }
                        break
                    case 'Price':
                        this.selectedOptions[key].forEach(element => {
                            this.filters.forEach(filtrs => {
                                filtrs.filters.forEach(filter => {
                                    if (filter.name == 'Price') {
                                        filter.data.forEach(elemt => {
                                            if (element == elemt.name) {
                                                price.push({
                                                    max: elemt.max,
                                                    min: elemt.min,
                                                })
                                            }
                                        })
                                    }
                                })
                            })
                        })
                        if (price.length > 0) {
                            this.$set(obj, 'price', price)
                        }
                        break
                    case 'Color':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Color',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    case 'Material':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Material',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    case 'Shape':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Shape',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    case 'Usage':
                        if (this.selectedOptions[key].length > 0) {
                            property.push({
                                key: 'Usage',
                                values: this.selectedOptions[key],
                            })
                        }
                        if (property.length > 0) {
                            this.$set(obj, 'properties', property)
                        }
                        break
                    default:
                        break
                }
            }

            this.$emit('filter', obj)
            //  filters: {
            //         vendor: this.selectedVendor,
            //         categorisation: {
            //             category_groups:
            //                 this.selectedCategory.length > 0
            //                     ? this.selectedCategory
            //                     : undefined,
            //             basecategories:
            //                 this.selectedSubCategory.length > 0
            //                     ? this.selectedSubCategory
            //                     : undefined,
            //             subcategories:
            //                 this.selectedInnerCategory.length > 0
            //                     ? this.selectedInnerCategory
            //                     : undefined,
            //         },
            //         price: this.selectedPrices,
            //         properties: this.selectedProperty,
            //     },
        },
        closeModal() {
            this.showMenu = false
        },
        clearAllfilters() {
            this.selectedOptions = {
                Vendors: [],
                Categories: [],
                Color: [],
                Material: [],
                Price: [],
                Shape: [],
                Usage: [],
            }
            this.selectedInnerCategory = []
            this.selectedSubCategory = []
            this.showMoreOptions = {
                Vendors: true,
                Categories: true,
                Color: true,
                Material: true,
                Price: true,
                Shape: true,
                Usage: true,
            }
            this.clearAll = true
            let obj = {}
            const checkboxes = this.$refs.checkboxesContainer.querySelectorAll(
                'input[type="checkbox"]',
            )

            checkboxes.forEach(checkbox => (checkbox.checked = false))
            this.$emit('filter', {
                obj,
                filters: {},
            })
        },
        showMoreItems(filterType, count, data) {
            if (count <= 15) {
                this.showMoreOptions[filterType] = false
            } else {
                this.modalData = data
                this.showMenu = true
                this.menuType = filterType
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './ProductFilters.scss';
</style>
