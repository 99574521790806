var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters-box"},[_c('div',{staticClass:"header"},[_c('p',[_vm._v("Filter")]),_c('p',{staticClass:"clear-all",on:{"click":_vm.clearAllfilters}},[_vm._v("Clear All")])]),_c('div',{staticClass:"filters-container"},[(!_vm.showMenu)?_c('Tabs',{attrs:{"isselected":0,"fullWidth":true}},_vm._l((_vm.filters),function(tab){return _c('Tab',{key:tab.filter_type,attrs:{"title":tab.filter_type == 'Visual'
                        ? tab.filter_type
                        : 'Products'}},_vm._l((tab.filters),function(filters){return _c('div',{key:filters.name,ref:"checkboxesContainer",refInFor:true,staticClass:"filter-checkbox"},[_c('div',{staticClass:"filter-name"},[_vm._v(" "+_vm._s(filters.name)+" ")]),_c('div',{staticClass:"checkbox-list"},[_vm._l((filters.data.slice(
                                0,
                                4
                            )),function(filter,index){return _c('div',{key:index},[_c('CheckBoxData',{attrs:{"label":filter.name,"inputValue":filter.id ? filter.id : filter.name,"image":filter.image ? filter.image : ''},model:{value:(_vm.selectedOptions[filters.name]),callback:function ($$v) {_vm.$set(_vm.selectedOptions, filters.name, $$v)},expression:"selectedOptions[filters.name]"}}),(
                                    filters.name === 'Categories' &&
                                        _vm.selectedOptions.Categories.includes(
                                            filter.id
                                        )
                                )?_c('div',{staticClass:"category-options"},_vm._l((filter.categories),function(category){return _c('div',{key:category.id},[_c('CheckBoxData',{staticClass:"category-checkbox",attrs:{"label":category.name,"inputValue":category.id},model:{value:(_vm.selectedSubCategory),callback:function ($$v) {_vm.selectedSubCategory=$$v},expression:"selectedSubCategory"}}),(
                                            _vm.selectedSubCategory.includes(
                                                category.id
                                            )
                                        )?_c('div',{staticClass:"subcategory-options"},_vm._l((category.subcategories),function(sub){return _c('CheckBoxData',{key:sub.id,staticClass:"subcategory-checkbox",attrs:{"label":sub.name,"inputValue":sub.id},model:{value:(_vm.selectedInnerCategory),callback:function ($$v) {_vm.selectedInnerCategory=$$v},expression:"selectedInnerCategory"}})}),1):_vm._e()],1)}),0):_vm._e()],1)}),(
                                filters.data.slice(4).length > 0 &&
                                    _vm.showMoreOptions[filters.name] == true
                            )?_c('div',{staticClass:"products-count",on:{"click":function($event){_vm.showMoreItems(
                                    filters.name,
                                    filters.data.slice(4).length,
                                    filters.data
                                )}}},[_vm._v(" + "+_vm._s(filters.data.slice(4).length)+" more ")]):_vm._e(),(_vm.showMoreOptions[filters.name] == false)?_vm._l((filters.data.slice(4)),function(filter,index){return _c('div',{key:index},[_c('CheckBoxData',{attrs:{"label":filter.name,"inputValue":filter.id ? filter.id : filter.name,"image":filter.image},model:{value:(_vm.selectedOptions[filters.name]),callback:function ($$v) {_vm.$set(_vm.selectedOptions, filters.name, $$v)},expression:"selectedOptions[filters.name]"}})],1)}):_vm._e()],2)])}),0)}),1):_c('div',{staticClass:"option-modal"},[_c('div',{staticClass:"close-btn",on:{"click":_vm.closeModal}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"24","fill":"#d3d3d3","viewBox":"0 0 25 24"}},[_c('path',{staticClass:"cls1",attrs:{"d":"M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"}})])]),_c('div',{staticClass:"list"},[(_vm.menuType === 'Vendors')?_vm._l((_vm.letters),function(letter){return _c('div',{key:letter,staticClass:"by-letter"},[_vm._v(" "+_vm._s(letter)+" "),_vm._l((_vm.sortedVendors),function(option,index){return _c('div',{key:'option' + index,staticStyle:{"margin-bottom":"7px"}},[(
                                    letter == option.name[0].toUpperCase()
                                )?_c('CheckBoxData',{ref:"checkboxesContainer",refInFor:true,attrs:{"label":option.name,"inputValue":option.id},on:{"input":function($event){return _vm.toggleSelected(
                                        _vm.menuType,
                                        option.name,
                                        option.id
                                    )}},model:{value:(_vm.selectedOptions[_vm.menuType]),callback:function ($$v) {_vm.$set(_vm.selectedOptions, _vm.menuType, $$v)},expression:"selectedOptions[menuType]"}}):_vm._e()],1)})],2)}):_vm._l((_vm.modalData),function(option,index){return _c('div',{key:'option' + index,staticClass:"by-letter"},[_c('CheckBoxData',{ref:"checkboxesContainer",refInFor:true,attrs:{"label":option.name,"inputValue":option.name,"image":option.image},on:{"input":function($event){_vm.toggleSelected(
                                    _vm.menuType.toLowerCase(),
                                    option.name,
                                    option.id
                                )}}})],1)})],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }